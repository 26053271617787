import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { router } from "@inertiajs/react";
import { FormattedMessage } from "react-intl";
import Button from "@/components/common/buttons/button";
import ColorLink from "@/components/common/links/colorLink";

export default function IndustryExperienceModal() {
    return (
        <Dialog
            open
            onClose={() => router.post(route("home.dismissIndustryExperience"))}
            className="relative z-[999]"
        >
            <div className="fixed inset-0 bg-gray-500/75" aria-hidden="true" />
            <div className="fixed inset-0 flex w-screen items-center justify-center p-8">
                <DialogPanel className="w-full sm:max-w-lg rounded bg-white p-8">
                    <div>
                        <div className="flex items-center">
                            <div className="mx-auto flex-shrink-0 flex items-center justify-center size-12 rounded-full bg-rose-100 border-rose-100 border-4 sm:mx-0 sm:size-10">
                                <CheckCircleIcon className="size-6 text-rose-600" />
                            </div>
                            <DialogTitle className="text-2xl leading-6 text-gray-900 font-light ml-4">
                                <FormattedMessage id="Please complete" />{" "}
                                <span className="font-bold">
                                    <FormattedMessage id="your profile" />
                                </span>
                            </DialogTitle>
                        </div>
                        <div className="mt-8 text-left">
                            <div className="mt-4">
                                <p className="text-base text-gray-700">
                                    <FormattedMessage id="We all have a lifetime of skills and experience, both lived and professional. Many of our members express interest in contributing their professional skills & knowledge to research & innovation, and so for us to create these opportunities we'd love to know a little bit about your background. Help us by completing your profile." />
                                </p>
                            </div>
                            <div className="mt-8 flex justify-end space-x-4">
                                <Button
                                    color="grey"
                                    onClick={() =>
                                        router.post(
                                            route(
                                                "home.dismissIndustryExperience"
                                            )
                                        )
                                    }
                                >
                                    <FormattedMessage id="Dismiss" />
                                </Button>
                                <ColorLink
                                    color="voice"
                                    href={route("user-settings")}
                                >
                                    <FormattedMessage id="Complete your profile" />
                                </ColorLink>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
}
